<!--
 * @Description: 学习详情
 * @Author: xiawenlong
 * @Date: 2021-04-28 18:21:09
 * @LastEditors: xiawenlong
 * @LastEditTime: 2023-05-06 08:56:07
-->
<template>
  <div class="class-study-info">
    <table-list
      :title="this.$route.params.className + '学习记录'"
      :loading="loading"
      :data="recordList"
      :search-form="searchForm(this)"
      :btns="btns(this)"
      :columns="columns(this)"
      :pager="pager"
      :options="{ selection: true, operatesWidth: 200 }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 搜索框配置
const searchForm = () => [
  {
    label: '员工名称',
    prop: 'studentName',
  },
  {
    label: '员工手机号',
    prop: 'phone',
  },
  // {
  //   label: '学员身份证',
  //   prop: 'idCard',
  // },
]
const btns = _this => [
  {
    label: '个人学习详情',
    method: _this.handleAllStudentDetail,
  },
  {
    label: '导出学习记录',
    method: _this.handleAddByLibrary,
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'studentName',
    label: '员工名称',
    minWidth: 120,
    render: (h, { row }) => {
      return h('div', [
        h(
          'div',
          {
            style: {
              cursor: 'pointer',
              color: '#409EFF',
            },
            on: {
              click: function() {
                _this.$router.push({
                  path: '/class/studentDetail',
                  query: {
                    userId: row.userId,
                    classId: _this.$route.params.classId,
                    className: _this.$route.params.className,
                  },
                })
              },
            },
          },
          row.studentName,
        ),
      ])
    },
  },
  {
    prop: 'phone',
    minWidth: 120,
    label: '手机号',
    width: '120',
  },
  {
    prop: 'idCard',
    label: '身份证号',
    width: '180',
  },
  {
    prop: 'studyTimeRange',
    minWidth: 220,
    label: '学习开始到完成时间',
    render: (h, { row }) => {
      return h('div', [
        h('div', row.studyBeginTime ? '开始：' + row.studyBeginTime : ''),
        h('div', row.studyEndTime ? '结束：' + row.studyEndTime : ''),
      ])
    },
  },
  // {
  //   prop: 'durationCount',
  //   label: '身份证号',
  // },
  {
    prop: 'studyDaysCount',
    minWidth: 120,
    align: 'right',
    sortable: true,
    label: '学习天数',
  },
  {
    prop: 'punchClockCount',
    minWidth: 100,
    align: 'right',
    sortable: true,
    label: '打卡次数',
  },
  {
    prop: 'studyDurationSum',
    minWidth: 160,
    align: 'right',
    sortable: true,
    label: '已学时长（分钟）',
  },
  {
    prop: 'courseDurationSum',
    minWidth: 160,
    align: 'right',
    sortable: true,
    label: '应学时长（分钟）',
  },
  // {
  //   prop: 'durationCount',
  //   label: '课程状态',
  // },
  {
    prop: 'finalCompletedSectionCount',
    label: '时长超额完成章节',
    minWidth: 80,
    align: 'right',
  },
  {
    prop: 'completedSectionCount',
    label: '完成章节数',
    minWidth: 120,
    align: 'right',
    sortable: true,
  },
  {
    prop: 'sectionTotal',
    minWidth: 120,
    align: 'right',
    sortable: true,
    label: '应学章节数',
  },
  {
    prop: 'progress',
    minWidth: 100,
    align: 'right',
    sortable: true,
    label: '进度',
  },
  {
    prop: 'completedExamCount',
    label: '考试完成',
    minWidth: 120,
    align: 'right',
    sortable: true,
  },
  {
    prop: 'examMaxScore',
    label: '最高分数',
    minWidth: 120,
    align: 'right',
    sortable: true,
  },
  {
    prop: 'creditSum',
    minWidth: 120,
    align: 'right',
    sortable: true,
    label: '获取学分',
  },
]
import TableList from '@/components/TableList'
import { classStudyhistory, studentExport } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassStudyInfo',
  components: {
    TableList,
  },
  data() {
    return {
      searchForm,
      btns,
      columns,
      loading: false,
      recordList: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
    }
  },
  mounted() {
    this.classStudyhistoryData()
  },
  methods: {
    async classStudyhistoryData() {
      if (this.loading) return
      const { current, size } = this.pager
      const { classId } = this.$route.params
      this.loading = true
      const [res, err] = await to(
        classStudyhistory({ classId, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.recordList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.classStudyhistoryData()
    },
    async handleAddByLibrary() {
      let arr = []
      if (this.select.length) this.select.map(v => arr.push(v.studentId))
      const { classId } = this.$route.params
      const [res, err] = await to(
        studentExport({ classId, studentIdList: arr, ...this.queryParams }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '班级员工学习记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
      // this.$router.push(`/class/addCourse/${classId}/library`)
    },
    handleAddByShop() {
      const { classId } = this.$route.params
      this.$router.push(`/class/addCourse/${classId}/shop`)
    },
    handleAllStudentDetail() {
      this.$router.push({
        path: '/class/studentDetail',
        query: { classId: this.$route.params.classId, className: this.$route.params.className },
      })
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.classStudyhistoryData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.classStudyhistoryData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
  },
}
</script>
<style lang="scss" scoped>
.class-add-course {
  .subTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #4d4e5d;
    margin-bottom: 12px;
  }
}
</style>
